:root {
  --jumbotron-padding-y: 3rem;
}

.jumbotron {
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: 0;
  background-color: #fff;
}
@media (min-width: 768px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 2);
  }
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron-heading {
  font-weight: 300;
}

.jumbotron .container {
  max-width: 40rem;
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer p {
  margin-bottom: .25rem;
}

// number of cards per line for each breakpoint
$cards-per-line: (
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4
);

@each $name, $breakpoint in $grid-breakpoints {
	@media (min-width: $breakpoint) {
		.card-deck .card {
			flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
		}
	}
}

.recipe-cards .card-img-top {
  height: 160px;
  object-fit: cover;
}

.recipe-cards a {
  text-decoration: none;
}

.recipe h1, .recipe .h1 { font-size: $font-size-base * 1.5; }
.recipe h2, .recipe .h2 { font-size: $font-size-base * 1.15; }
.recipe h3, .recipe .h3 { font-size: $font-size-base; }
.recipe h4, .recipe .h4 { font-size: $font-size-base; }
.recipe h5, .recipe .h5 { font-size: $font-size-base; }
.recipe h6, .recipe .h6 { font-size: $font-size-base; }

.recipe .recipe-ingredients li {
  margin-bottom: .5rem;
}
.recipe .recipe-ingredients li {
  list-style-type:none;
}
.recipe .recipe-ingredients li:before {
  @extend %fa-icon;
  @extend .far;
  content: fa-content($fa-var-check);
  margin: 0 10px 0 -28px;
  color: $secondary;
}
.recipe .recipe-ingredients .equipment li:before {
  @extend %fa-icon;
  @extend .far;
  content: fa-content($fa-var-dolly-flatbed-alt);
  margin: 0 10px 0 -28px;
  color: $secondary;
}
.recipe .recipe-ingredients em {
  font-size: $font-size-base * 0.9;
  color: $secondary;
}

.recipe .recipe-method ol {
  list-style-type: none;
  counter-reset: item;
}
.recipe .recipe-method ol li:before {
  content: counter(item) "  ";
  counter-increment: item;
  color: $secondary;
  position: absolute;
  top: 0px;
  left: -50px;
  width: 40px;
  text-align: right;
  font-size: 1.3rem;
  font-weight: 500;
}
.recipe .recipe-method ol li {
  margin-bottom: 1.3rem;
  padding-left: 1rem;
  border-left: 1px solid $secondary;
  min-height: 1.8rem;
  position: relative;
}

.recipe-index li {
  margin-bottom: .5rem;
}
.recipe-index li {
  list-style-type:none;
}
.recipe-index li:before {
  @extend %fa-icon;
  @extend .far;
  content: fa-content($fa-var-file-alt);
  margin: 0 10px 0 -28px;
  color: $secondary;
}

@include media-breakpoint-down(sm) {
  $font-scaling-factor: 0.85;
  h1, .h1 { font-size: $h1-font-size * $font-scaling-factor; }
  h2, .h2 { font-size: $h2-font-size * $font-scaling-factor; }
  h3, .h3 { font-size: $h3-font-size * $font-scaling-factor; }
  h4, .h4 { font-size: $h4-font-size * $font-scaling-factor; }
  h5, .h5 { font-size: $h5-font-size * $font-scaling-factor; }
  h6, .h6 { font-size: $h6-font-size * $font-scaling-factor; }
  .lead { font-size: $lead-font-size * $font-scaling-factor; }
  .recipe-info { font-size: $font-size-base * $font-scaling-factor; }
  .jumbotron { padding: 0rem; }
}
